import React, { useEffect, useState } from "react";
import { Container, Card, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useNavigate } from "react-router-dom";
import { TextareaAutosize, ThemeProvider, createTheme } from "@mui/material";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import { Row, Col, Button } from "react-bootstrap";
import { TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { isEmpty } from "lodash";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import { InputAdornment } from "@mui/material";
import {
  deleteShipment,
  getShipment,
  getShipmentById,
  getShipmentByIdForPdf,
  getShipmentStatus,
  postVoucher,
  setEditShipmentData,
  updateShipment,
  updateShipmentAddStatus,
  updateShipmentStatus,
} from "./Shipment.action";
import Swal from "sweetalert";
import { baseURL, headers } from "../../../global/api";
import {
  getShipmentByTrackingId,
  setEditDate,
  setEditStatusFields,
} from "../tracking/TrackCargo.action";
import { setEstimatedDate } from "./createShipment/CreateShipment.action";
const ViewShipment = (props) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  const navigate = useNavigate();
  const defaultMaterialTheme = createTheme();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeEstimatedDeliveryDate = (e) => {
    props.setEstimatedDate(e.target.value);
  };
  const [addStatus, setAddStatus] = useState("");
  const [addStatusLocation, setAddStatusLocation] = useState("");
  const [ID, setID] = useState("");

  const [addStatusModal, setAddStatusModal] = useState(false);
  const handleShowAddStatus = (id) => {
    setAddStatusModal(true);
    setID(id);
  };
  const handleCloseAddStatus = () => {
    setID("");
    setAddStatusModal(false);
  };
  function uploadStatus(id) {
    console.log(id, props.estimatedDeliveryDateEdit, 'aaaaaaaaaqqqqqqqqqqqqqq')
    // props.updateShipmentStatus(newData, oldData);
    props.updateShipmentStatus(props.shipmentByTrackingId, props.estimatedDeliveryDateEdit, id);
    setAddStatusModal(false);
    setID("");
    setAddStatus("");
    setAddStatusLocation("")
  }
  const [addStatusModal1, setAddStatusModal1] = useState(false);
  const handleShowAddStatus1 = (id) => {
    setAddStatusModal1(true);
    setID(id);
  };
  const handleCloseAddStatus1 = () => {
    setID("");
    setAddStatusModal1(false);
  };
  function uploadAddStatus(id) {
    // props.updateShipmentStatus(newData, oldData);
    props.updateShipmentAddStatus({ status: addStatus, status_location: addStatusLocation }, id);
    setAddStatusModal1(false);
    setID("");
    setAddStatus("");
    setAddStatusLocation("");
  }
  const changeStatus = (e, key, i) => {
    const list = [...props.shipmentByTrackingId];

    list[i][key] = e.target.value;
    props.setEditStatus(list);
  };
  const handleRemoveStatus = (i) => {
    const list = [...props.shipmentByTrackingId];
    list.splice(i, 1);
    props.setEditStatus(list);
  };
  useEffect(() => {
    // props.getShipment();
    props.getShipmentStatus();
    document.title = props.title;
  }, []);
  return (
    <>
      {props.isFetchingShipmentList || props.isFetchingShipmentStatus ? (
        <div className="loader-div">
          <Spinner
            variant="secondary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div
            className="main"
            style={{ height: "100%", backgroundColor: "#F9FAFB" }}
          >
            <ThemeProvider theme={defaultMaterialTheme}>
              <MaterialTable
                style={{
                  borderRadius: "30px",
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                title="Shipment Details"
                columns={[
                  // {
                  //   title: "Shipment Id",
                  //   field: "shipment_id",
                  //   editable: "never",
                  // },
                  {
                    title: "Tracking Id",
                    field: "tracking_id",
                    editable: "never",
                  },
                  {
                    title: "Shipment Type",
                    field: "shipment_type",
                    editable: "never",
                  },
                  // {
                  //   title: "Shipment purpose",
                  //   field: "shipment_purpose.purpose",
                  //   editable: "never",
                  // },
                  {
                    title: "Shipment Refrence",
                    field: "shipment_ref",
                    editable: "never",
                  },
                  {
                    title: "Tax Type",
                    field: "shipment_tax_type.name",
                    editable: "never",
                  },
                  { title: "Date", field: "shipment_date", editable: "never" },
                  // {
                  //   title: "Total Packages",
                  //   field: "total_packages",
                  //   editable: "never",
                  // },
                  {
                    title: "Total Weight",
                    field: "total_weight",
                    editable: "never",
                  },
                  {
                    title: "Expected Delivery Date",
                    field: "expected_delivery_date",
                    editable: "never",
                  },
                  // { title: "Status", field: "statu.status"},
                  {
                    title: "Status",
                    field: "track_status",
                    // editComponent: ({ value, onChange }) => (
                    //   <select
                    //     onChange={(e) => onChange(e.target.value)}
                    //     style={{ padding: "10px" }}
                    //   >
                    //     <option selected value={value}>
                    //       {value}
                    //     </option>
                    //     {props.shipmentStatus.map(
                    //       (item) =>
                    //         item.status !== value &&
                    //         (
                    //           <option
                    //             key={item.status_id}
                    //             value={item.status_id}
                    //           >
                    //             {item.status}
                    //           </option>
                    //         ))
                    //     )}
                    //   </select>
                    // ),
                  },
                ]}
                // data={props.shipmentList}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    let url = `${baseURL}/shipment?`;
                    url += "size=" + query.pageSize;
                    url += "&page=" + (query.page + 1);
                    url += "&search=" + query.search;
                    fetch(url, {
                      method: "GET",
                      headers: headers,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          data: result.data,
                          page: result.page - 1,
                          totalCount: result.total_results,
                        });
                      });
                  })
                }
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  paging: true,
                  pageSize: 200, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [150, 200, 250, 300, 400, 500, 750, 1000], // rows selection options
                  maxBodyHeight: "600px",
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#919EAB",
                    fontFamily: "Public Sans,sans-serif",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
                editable={{
                  // isEditHidden: (rowData) =>
                  //   Checkpermissions(
                  //     "permissions_cat_name",
                  //     "Shipment Edit Status"
                  //   ),
                  isDeleteHidden: (rowData) =>
                    Checkpermissions("permissions_cat_name", "Shipment Delete"),
                  // onRowUpdate: () =>
                  //   // handleShowAddStatus(),
                  //   new Promise((resolve) => {
                  //     setTimeout(() => {
                  //       resolve();
                  //       // props.updateShipmentStatus(newData, oldData);
                  //     }, 600);
                  //   }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        props.deleteShipment(oldData.shipment_id);
                      }, 600);
                    }),
                }}
                // <VisibilityIcon/>

                actions={[
                  {
                    icon: () => <VisibilityIcon />,
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "Shipment Edit"
                    ),
                    tooltip: "Edit Shipment",
                    onClick: (event, rowData) => {
                      props.getShipmentById(rowData.shipment_id);
                      navigate("/cargo/shipment/edit_shipment");
                    },
                  },
                  {
                    icon: () => <SendIcon />,
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "Shipment Post"
                    ),
                    disabled: true,
                    tooltip: "Post Voucher",
                    onClick: (event, rowData) => {
                      props.postVoucher(
                        rowData.shipment_id,
                        handleVisible,
                        handleDangerVisible
                      );
                      // navigate("/cargo/shipment/edit_shipment");
                    },
                  },
                  {
                    icon: () => <AddCircleIcon />,
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "Shipment Edit Status"
                    ),
                    tooltip: "Add Status",
                    onClick: (event, rowData) => {
                      props.getShipmentByTrackingId(rowData.tracking_id);
                      handleShowAddStatus1(rowData.shipment_id);
                    },
                  },
                  {
                    icon: () => <EditIcon />,
                    hidden: Checkpermissions(
                      "permissions_cat_name",
                      "Shipment Edit Status"
                    ),
                    tooltip: "Edit Status",
                    onClick: (event, rowData) => {
                      props.getShipmentByTrackingId(rowData.tracking_id);
                      handleShowAddStatus(rowData.shipment_id);
                    },
                  },
                  // {
                  //   icon: () => <PictureAsPdfIcon />,
                  //   // disabled:invoice_type==true?false:true,
                  //   hidden: Checkpermissions(
                  //     "permissions_cat_name",
                  //     "Shipment Edit"
                  //   ),
                  //   tooltip: "Generate Invoice",
                  //   onClick: (event, rowData) => {
                  //     props.getShipmentByIdForPdf(rowData.shipment_id);
                  //     // navigate("/cargo/shipment/edit_shipment");
                  //   },
                  // },
                ]}
              />
            </ThemeProvider>
            {/* status edit or delete modal   */}
            <Modal
              show={addStatusModal}
              onHide={handleCloseAddStatus}
              animation={false}
              style={
                {
                  // marginTop: "10%",
                  // zIndex:10,
                }
              }
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Shipment Status</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {!isEmpty(props.shipmentByTrackingId) &&
                  props.shipmentByTrackingId.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Container>
                          <Row>
                            <Col xl="5" lg="5" md="5" sm="5">
                              <Form.Group className="mb-3" controlId="garana">
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  id="warehouseName"
                                  placeholder="Shipment Status...."
                                  // defaultValue={props.employeeListById.allocation}
                                  value={item.status}
                                  onChange={(e) => {
                                    changeStatus(e, "status", i);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col xl="5" lg="5" md="5" sm="5">
                              <Form.Group className="mb-3" controlId="garana">
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  id="warehouseName"
                                  placeholder="Shipment Status...."
                                  // defaultValue={props.employeeListById.allocation}
                                  value={item.status_location}
                                  onChange={(e) => {
                                    changeStatus(e, "status_location", i);
                                  }}
                                />
                              </Form.Group>
                            </Col>

                            <Col xl="2" lg="2" md="2" sm="2" style={{ marginTop: '10px' }}>
                              {props.shipmentByTrackingId.length > 1 && (
                                <Button
                                  title="Click to Delete Shipment Status"
                                  onClick={() => handleRemoveStatus(i)}
                                  style={{
                                    marginRight: "2%",
                                    color: "black",
                                    float: "right",
                                    backgroundColor: "#C5CBCF",
                                    border: "1px solid white",
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </React.Fragment>
                    );
                  })}
                {console.log(props.shipmentByTrackingId, 'aaaaaaaaaaaaa')}
                <Row>
                  <Col>
                    {!isEmpty(props.shipmentByTrackingId) &&
                      // <b>Expected Delivery:{props.shipmentByTrackingId[0].shipment.expected_delivery_date}</b>
                      <TextField
                        InputProps={{
                          inputProps: {
                            min: moment(new Date()).format("YYYY-MM-DD"),
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <DateRangeTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        type="date"
                        id="standard-basic"
                        variant="standard"
                        label="Estimated Delivery Date"
                        value={props.estimatedDeliveryDateEdit}
                        // defaultValue={!isEmpty(props.shipmentByTrackingId)&& props.shipmentByTrackingId[0].shipment.expected_delivery_date}
                        onChange={handleChangeEstimatedDeliveryDate}
                        sx={{
                          width: "100%",
                        }}
                      />}
                  </Col>
                </Row>
                <div className="sendDiv" style={{ marginTop: "20px" }}>
                  <Button
                    // disabled={isEmpty(addStatus)}
                    style={{
                      marginLeft: "10%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={() => uploadStatus(ID)}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            {/* status add modal */}
            <Modal
              show={addStatusModal1}
              onHide={handleCloseAddStatus1}
              animation={false}
              style={
                {
                  // marginTop: "10%",
                  // zIndex:10,
                }
              }
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Shipment Status</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {/* {!isEmpty(props.shipmentByTrackingId) && props.shipmentByTrackingId.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Container> */}
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="warehouseName"
                        placeholder="Shipment Status...."
                        // defaultValue={props.employeeListById.allocation}
                        // value={addStatus}
                        onMouseLeave={(e) => {
                          setAddStatus(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        id="warehouseName"
                        placeholder="Status Location...."
                        // defaultValue={props.employeeListById.allocation}
                        // value={addStatusLocation}
                        onMouseLeave={(e) => {
                          setAddStatusLocation(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                  {!isEmpty(props.shipmentByTrackingId) &&
                  // <b>Expected Delivery:{props.shipmentByTrackingId[0].shipment.expected_delivery_date}</b>
          <TextField
            InputProps={{
              inputProps: {
                min: moment(new Date()).format("YYYY-MM-DD"),
              },
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type="date"
            id="standard-basic"
            variant="standard"
            label="Estimated Delivery Date"
            value={props.estimatedDeliveryDateEdit}
            // defaultValue={!isEmpty(props.shipmentByTrackingId)&& props.shipmentByTrackingId[0].shipment.expected_delivery_date}
            onChange={handleChangeEstimatedDeliveryDate}
            sx={{
              width: "100%",
            }}
          />}
                  </Col>
                </Row> */}
                {/* </Container>
                    </React.Fragment>
                  );
                })} */}
                <div className="sendDiv" style={{ marginTop: "20px" }}>
                  <Button
                    disabled={isEmpty(addStatus) || isEmpty(addStatusLocation)}
                    style={{
                      marginLeft: "10%",
                      color: "black",
                      width: "20%",
                      backgroundColor: "#C5CBCF",
                      border: "1px solid white",
                    }}
                    onClick={() => uploadAddStatus(ID)}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getShipment: (data) => dispatch(getShipment(data)),
    getShipmentByIdForPdf: (id) => dispatch(getShipmentByIdForPdf(id)),
    getShipmentById: (id) => dispatch(getShipmentById(id)),
    postVoucher: (id, handleVisible, handleDangerVisible) =>
      dispatch(postVoucher(id, handleVisible, handleDangerVisible)),
    updateShipmentStatus: (status, date, id) =>
      dispatch(updateShipmentStatus(status, date, id)),
    updateShipmentAddStatus: (status, id) =>
      dispatch(updateShipmentAddStatus(status, id)),
    getShipmentStatus: () => dispatch(getShipmentStatus()),
    deleteShipment: (shipment_id) => dispatch(deleteShipment(shipment_id)),
    updateShipment: (newData, oldData) =>
      dispatch(updateShipment(newData, oldData)),
    setShipmentData: (data) => dispatch(setEditShipmentData(data)),
    setEditStatus: (data) => dispatch(setEditStatusFields(data)),
    getShipmentByTrackingId: (id) => dispatch(getShipmentByTrackingId(id)),
    // setEstimatedDate: (data) => dispatch(setEstimatedDate(data)),
    setEstimatedDate: (data) => dispatch(setEditDate(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  senderList: state.CargoSenderReducer.senderList,
  isFetchingSender: state.CargoSenderReducer.isFetchingSender,
  isFetchingShipmentList: state.CargoShipmentReducer.isFetchingShipmentList,
  shipmentList: state.CargoShipmentReducer.shipmentList,
  isFetchingShipmentStatus: state.CargoShipmentReducer.isFetchingShipmentStatus,
  shipmentStatus: state.CargoShipmentReducer.shipmentStatus,
  shipmentById: state.CargoShipmentReducer.shipmentById,
  shipmentByTrackingId: state.TrackCargoReducer.shipmentByTrackingId,
  estimatedDeliveryDate: state.CargoCreateShipmentReducer.estimatedDeliveryDate,
  estimatedDeliveryDateEdit: state.TrackCargoReducer.estimatedDeliveryDateEdit
  // flavorsDataList: state.FlavourReducer.flavorsDataList,
  // sizeDataList: state.sizeReducer.sizeDataList,
  // masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewShipment);
